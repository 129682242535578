/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Layout,
  Loading,
  PaymentProviderTokenDeleteModal,
  SEO,
} from '../../../ui'
import iconBuilder from '../../../helpers/iconBuilder'
import useStyles from './account-payment-provider-tokens-style'

function AccountPaymentProviderTokensView(props) {
  const {
    loading,
    pageLoading,
    paymentProviderTokens = [],
    paymentTokenToBeDeleted = {},
    haveNextPage,
    showDeleteModal,
    onDelete,
    onDeleteModalRequestClose,
    onDeleteModalShow,
    onNextPage,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()

  const getIconName = (cardType) => {
    switch (cardType) {
      case 'JCB':
        return 'jcb'
      case 'MASTER':
        return 'mastercard'
      case 'VISA':
        return 'visa'
      default:
        return null
    }
  }

  return (
    <Layout login>
      <SEO title={t('screens.accountPaymentProviderTokens.seo.title')} />
      { pageLoading && <Loading />}
      <div className={styles.containerFluid}>
        <div className={styles.container}>
          <div className={styles.titleBox}>
            <h1>{t('screens.account.paymentProviderTokens.title')}</h1>
            <p>{t('screens.account.paymentProviderTokens.subtitle')}</p>
          </div>
          {
            _.isEmpty(paymentProviderTokens)
              ? (
                <div>{t('screens.account.paymentProviderTokens.empty.title')}</div>
              )
              : (
                <>
                  <div className={styles.list}>
                    {paymentProviderTokens.map((token) => {
                      const {
                        id,
                        cardType,
                        formattedCardNumber,
                      } = token
                      const iconName = getIconName(cardType)
                      return (
                        <div key={`token-item-${id}`} className={styles.item}>
                          <img className={styles.icon} src={iconBuilder(iconName)} alt={iconName} />
                          <div>
                            <div className={styles.textOptions}>{cardType}</div>
                            <div className={styles.textOptions}>{formattedCardNumber}</div>
                          </div>
                          <Button
                            className={styles.button}
                            text={t('screens.checkout.checkout.payment.paymentMethodSection.removePaymentToken')}
                            onClick={() => onDeleteModalShow(token)}
                          />
                        </div>
                      )
                    })}
                  </div>
                  {
                    haveNextPage && (
                      <Button
                        className={styles.button}
                        uppercase
                        // border
                        text={t('screens.account.paymentProviderTokens.buttons.next')}
                        onClick={onNextPage}
                        disabled={loading}
                      />
                    )
                  }
                </>
              )
          }
        </div>
      </div>
      <PaymentProviderTokenDeleteModal
        isOpen={showDeleteModal}
        detail={`${paymentTokenToBeDeleted.cardType} ${paymentTokenToBeDeleted.formattedCardNumber}`}
        onRequestClose={onDeleteModalRequestClose}
        onConfirm={onDelete}
      />
    </Layout>
  )
}

export default AccountPaymentProviderTokensView
