/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  usePayment,
} from 'react-omnitech-api'
import { withAuthenticableNavigationBlock } from '../../../ui'
import AccountPaymentProviderTokensView from './account-payment-provider-tokens-view'

function AccountPaymentProviderTokensController() {
  // prepare hook
  const { deletePaymentProviderToken, fetchPaymentProviderTokens } = usePayment()
  const { t } = useTranslation()

  // internal state
  const [pageLoading, setPageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [paymentProviderTokens, setPaymentProviderTokens] = useState([])
  const [isFirstFetch, setIsFirstFetch] = useState(true)
  const [nextPageFetch, setNextPageFetch] = useState(null)
  const [paymentProviderTokensUpdated, setPaymentProviderTokensUpdated] = useState(false)
  const [paymentTokenToBeDeleted, setPaymentTokenToBeDeleted] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  // local variable
  const seoTitle = t('screens.accountPaymentProviderTokens.seo.title')

  const getPaymentProviderTokens = useCallback(() => {
    const fetch = nextPageFetch || fetchPaymentProviderTokens
    const promise = fetch({
      includes: [
      ],
    })

    async function loadData() {
      setPageLoading(_.isEmpty(paymentProviderTokens))
      setLoading(true)
      setIsFirstFetch(false)

      try {
        const { paymentProviderTokens: data, next } = await promise
        const tokens = _.map(data, (token) => ({
          ...token,
          formattedCardNumber: _.replace(token.displayCardNumber, /x+/, '****'),
        }))

        if (paymentProviderTokensUpdated) setPaymentProviderTokens(tokens)
        else setPaymentProviderTokens([...paymentProviderTokens, ...tokens])

        setNextPageFetch(_.isFunction(next) ? () => next : null)
      } catch (err) {
        alert(err)
      } finally {
        setPageLoading(false)
        setLoading(false)
        setPaymentProviderTokensUpdated(false)
      }
    }

    loadData()
  }, [paymentProviderTokens, paymentProviderTokensUpdated, fetchPaymentProviderTokens, nextPageFetch])

  const onNextPage = getPaymentProviderTokens

  async function handleDeleteToken() {
    if (_.isEmpty(paymentTokenToBeDeleted)) {
      handleDeleteTokenModalRequestClose()
      return
    }
    await deletePaymentProviderToken({ paymentProviderTokenId: paymentTokenToBeDeleted.id })
    setPaymentProviderTokensUpdated(true)
    handleDeleteTokenModalRequestClose()
  }

  function handleDeleteTokenModalRequestClose() {
    setPaymentTokenToBeDeleted({})
    setShowDeleteModal(false)
  }

  function handleDeleteTokenModalShow(token) {
    setPaymentTokenToBeDeleted(token)
    setShowDeleteModal(true)
  }

  useEffect(() => (function cleanUp() {
    cancelRequest.cancelAll([
      'fetchPaymentProviderTokens',
    ])
  }), [])

  useEffect(() => {
    if (isFirstFetch || paymentProviderTokensUpdated) getPaymentProviderTokens()
  }, [paymentProviderTokens, paymentProviderTokensUpdated, getPaymentProviderTokens, isFirstFetch])

  const viewProps = {
    pageLoading,
    loading,
    paymentProviderTokens,
    paymentTokenToBeDeleted,
    haveNextPage: _.isFunction(nextPageFetch),
    showDeleteModal,
    onDelete: handleDeleteToken,
    onDeleteModalShow: handleDeleteTokenModalShow,
    onDeleteModalRequestClose: handleDeleteTokenModalRequestClose,
    onNextPage,
  }
  return (
    <AccountPaymentProviderTokensView {...viewProps} />
  )
}

export default withAuthenticableNavigationBlock(AccountPaymentProviderTokensController)
